import React, { useEffect, useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";
import aboutHeroImage from "../assets/marlow-hero.jpg";
import StylizedText from "../components/layout/StylizedText";

const JobDescription = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      {/* Header with Collapse Toggle */}
      <div
        className="flex justify-between items-center cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h1 className="text-2xl font-bold">{title}</h1>
        {isOpen ? <ChevronUp size={24} /> : <ChevronDown size={24} />}
      </div>

      {/* Collapsible Content */}
      {isOpen && <div className="mt-4">{children}</div>}
    </div>
  );
};

const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="bg-white">
      {/* Hero Section */}
      <section className="relative h-screen">
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: `url(${aboutHeroImage})`,
            filter: "grayscale(100%)",
          }}
        ></div>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative h-full flex items-start">
          <div className="container mx-auto px-4 pt-[25vh] text-white">
            <div className="max-w-3xl px-6 py-3">
              <h1 className="text-2xl font-bold mb-6 leading-tight">
                <StylizedText text="Fox Smith Associates is hiring" />
              </h1>
              <p className="text-xl mb-12 max-w-2xl">
                Personalised Service, Expert Tax Solutions
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Job Listings Section */}
      <section className="py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold mb-16 text-center">
            <StylizedText text="Current Job Openings" color="black" />
          </h2>
          <p className="text-1xl font-bold mb-16 text-center">
            Send your CV to info@foxsmith.co.uk
          </p>
          <p></p>
          <div className="max-w-3xl mx-auto space-y-8">
            {/* Qualified Accountant */}
            <JobDescription title="Qualified Accountant">
              <section className="mb-6">
                <h2 className="text-xl font-semibold">Firm</h2>
                <p className="text-gray-700">
                  We are a friendly boutique accountancy and tax advisory firm based in Marlow.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold">Duties</h2>
                <ul className="list-disc list-inside mt-2 text-gray-700">
                  <li>Managing a team of accountants</li>
                  <li>Reviewing team work and assisting with training</li>
                  <li>VAT returns</li>
                  <li>Year-end accounts completion</li>
                  <li>Corporation Tax return compliance</li>
                  <li>P11d compliance</li>
                  <li>Responding to HMRC enquiries</li>
                  <li>Ad-hoc tax advisory projects</li>
                </ul>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold">Skills</h2>
                <ul className="list-disc list-inside mt-2 text-gray-700">
                  <li>Proficiency in accounting software such as Xero, Sage, QuickBooks</li>
                  <li>Attention to detail and accuracy in data entry and analysis</li>
                  <li>Ability to work independently and meet deadlines</li>
                  <li>Strong communication skills, both written and verbal</li>
                </ul>
              </section>

              <section className="mb-6">
                <p className="text-gray-700">
                  <strong>Job Types:</strong> Full-time, Part-time, Permanent
                </p>
                <p className="text-gray-700">
                  <strong>Expected Hours:</strong> 20 - 40 per week
                </p>
                <p className="text-gray-700">
                  <strong>Education:</strong> GCSE or equivalent (preferred)
                </p>
                <p className="text-gray-700">
                  <strong>Work Location:</strong> In person - Marlow, UK
                </p>
              </section>
            </JobDescription>

            {/* Part Qualified Accountant */}
            <JobDescription title="Part Qualified Accountant">
              <p className="text-gray-700">Similar duties & skills as the Qualified Accountant.</p>
              <p className="text-gray-700">Full study support provided.</p>
            </JobDescription>

            {/* Personal Assistant */}
            <JobDescription title="Personal Assistant">
              <section className="mb-6">
                <h2 className="text-xl font-semibold">Firm</h2>
                <p className="text-gray-700">
                  We are a friendly boutique accountancy and tax advisory firm based in Marlow.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold">Duties</h2>
                <p className="text-gray-700">
                  We are looking for an experienced and organised PA to support the founder with administration.
                </p>
                <p className="text-gray-700">
                  The ideal candidate will have a number of years of experience supporting executives with diary management and projects.
                </p>
              </section>

              <section className="mb-6">
                <h2 className="text-xl font-semibold">Skills</h2>
                <p className="text-gray-700">
                  Excellent communication and organisational skills are required.
                </p>
              </section>

              <section className="mb-6">
                <p className="text-gray-700">
                  <strong>Job Types:</strong> Full-time, Permanent
                </p>
                <p className="text-gray-700">
                  <strong>Work Location:</strong> In person - Marlow, UK
                </p>
              </section>
            </JobDescription>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Careers;
