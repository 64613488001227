import React, { useState } from "react";
import { Link } from "react-router-dom";
import SocialMedia from "../common/SocialMedia";

import foxLogoSmall from "../../assets/home-hero-fox.jpg";
// Christmas time only
// import foxLogoSmall from "../../assets/logo_fox_smith_associates_christmas_1_compressed_cropped.png";
import StylizedText from "./StylizedText";

const MenuIcon = ({ isOpen }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    {isOpen ? (
      <path d="M18 6L6 18M6 6l12 12" />
    ) : (
      <>
        <line x1="3" y1="12" x2="21" y2="12" />
        <line x1="3" y1="6" x2="21" y2="6" />
        <line x1="3" y1="18" x2="21" y2="18" />
      </>
    )}
  </svg>
);

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = ["Home", "About", "Services", "International", "Contact", "Careers"];

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <header className="bg-black shadow-md py-4">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center">
          <Link to="/" className="flex items-center">
            <img
              src={foxLogoSmall}
              alt="Fox Smith Logo"
              className="w-8 h-8 mr-1 rounded-full shadow-xl dark:shadow-gray-800 opacity-90"
            />
            <StylizedText text="FOX SMITH" />
          </Link>
          <nav className="hidden lg:block">
            <ul className="font-serif flex items-center space-x-8">
              {navItems.map((item) => (
                <li key={item}>
                  {item === "Contact" ? (
                    <Link
                      to="/contact"
                      className="font-serif inline-block bg-transparent border border-orange-650 text-orange-650 px-6 py-2 
                     hover:bg-orange-650 hover:text-black transition duration-300 uppercase 
                     tracking-wider text-sm font-semibold"
                    >
                      GET IN TOUCH
                    </Link>
                  ) : (
                    <Link
                      to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                      className="font-serif text-white hover:text-gray-600 transition duration-300 text-sm uppercase tracking-wider"
                    >
                      {item}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className="hidden lg:block">
            <SocialMedia />
          </div>
          <button
            className="lg:hidden text-white"
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <MenuIcon isOpen={isMenuOpen} />
          </button>
        </div>
      </div>
      {/* Mobile and Tablet menu */}
      {isMenuOpen && (
        <div className="lg:hidden mt-6">
          <nav>
            <ul className="flex flex-col space-y-6 items-end">
              {navItems.map((item) => (
                <li key={item}>
                  {item === "Contact" ? (
                    <Link
                      to="/contact"
                      className="inline-block bg-transparent text-orange-650 px-6 py-3 
                     hover:bg-orange-650 hover:text-black transition duration-300 uppercase 
                     tracking-wider text-xl font-semibold"
                      onClick={toggleMenu}
                    >
                      GET IN TOUCH
                    </Link>
                  ) : (
                    <Link
                      to={item === "Home" ? "/" : `/${item.toLowerCase()}`}
                      className="block text-white hover:text-gray-600 transition duration-300 text-xl uppercase tracking-wider px-6 py-3"
                      onClick={toggleMenu}
                    >
                      {item}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <div className="mt-6 px-6">
            <SocialMedia />
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
